import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

axiosClient.interceptors.request.use(function (request) {
  request.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjEifQ.eyJkYXRhIjp7ImlkIjoxLCJyb2xlcyI6WyJBRE1JTiJdLCJlbWFpbCI6Imx1Y2FzQGhvb3BheS5jb20uYnIiLCIyQUYiOnsiYWN0aXZhdGVkIjpmYWxzZSwidmFsaWQiOmZhbHNlfX0sImlhdCI6MTcyNTY2OTkxNywiZXhwIjoxNzI2Mjc0NzE3fQ.s2QpFEhBRi1ZlZsShCFPzl17Wlb8svS7VBvAZUK1Mh4`;
  return request;
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // if (error.response && error.response.status === 401) {
      
    // }

    // if (error.response && error.response.status === 403 && error.response?.data.errors.find((e: any) => e.uniqueCode === "2AF:REQUEST_INVALID")) {

    // }

    return Promise.reject(error);
  }
);

export default axiosClient;
