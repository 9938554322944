import CartView from '@/views/cart/CartView.vue'
import HomeView from '@/views/HomeView.vue'
import ShowProductView from '@/views/products/ShowProductView.vue'
import ShopView from '@/views/shop/ShopView.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/product/:slug',
    name: 'product',
    props: true,
    component: ShowProductView
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView
  },
  {
    path: '/shop',
    name: 'shop',
    component: ShopView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
